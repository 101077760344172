<template>
  <div>
    Redirecting...
  </div>
</template>
<script>
export default {
  name: 'LoginForm',

  created () {
    this.$auth.login()
  }
}
</script>